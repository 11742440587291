<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Partnership
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer
      ref="addCategoryFormalidate"
      v-slot="{ validate }"
    >
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="handleSubmit(validate)"
      >
        <b-row>

          <b-col
            cols="12"
            class="border rounded p-2 mb-2"
          >
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Select Category"
                  class="mb-2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Category"
                  >
                    <v-select
                      v-model="addCategoryForm.category_id"
                      :options="categoriesList"
                      label="type"
                      :reduce="val => val.id"
                      placeholder="Select a category"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6">
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <div class="d-inline-block">
                    <b-media-aside>
                      <b-img
                        :src="addCategoryForm.image"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                    </b-media-aside>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      class="rounded ml-1 mt-2 text-center"
                      @click="removeImage(index)"
                    >
                      Remove Image
                    </b-button>
                  </div>
                  <b-media-body>
                    <b-card-text class="mt-1" />
                    <div class="d-inline-block mt-1">
                      <b-form-group
                        label="Upload Image"
                        class="mb-2"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Image"
                        >
                          <b-form-file
                            v-model="addCategoryForm.image"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @change="e => inputImageRenderer(e)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Name">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Name"
                  >
                    <b-form-input
                      v-model="addCategoryForm.name"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Link"
                  class="mt-2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                    name="Link"
                  >
                    <b-form-input
                      v-model="addCategoryForm.link"
                      placeholder="Link"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props) {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }
    const refPreviewE2 = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    store.dispatch('categories/Getsponsors', { id }).then(response => {
      store.commit('categories/GET_Partner', response?.data)
    })

    const CategoryForm = computed(() => store.state.categories.partner)
    const addCategoryForm = reactive(CategoryForm)

    const inputImageRenderer = async (event, index) => {
      const token = localStorage.getItem('token')
  isLoading.value = true
      const file = event.target.files[0]
      if (file) {
        
        const reader = new FileReader()
        reader.onload = async (e) => {
          const formData = new FormData()
          formData.append('image', file)
          
          try {
            const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`, formData, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
         
              isLoading.value = false
            addCategoryForm.value.image= response.data.location // Assuming the API response contains the URL in `location`
          } catch (error) {
            console.error('Image upload failed:', error)
            // Handle error appropriately
          }
        }
        reader.readAsBinaryString(file)
      }
    }
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const categoriesList = ref([])
    store.dispatch('categories/AllCategory')
      .then(response => {
        categoriesList.value = response.data.data
      })

    const addCategoryFormalidate = ref()
    const removeSpace = target => {
      addCategoryForm.value.link_id = target.toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
        const isLoading = ref(false)
    const save = () => {

let result={...addCategoryForm.value}
      
        addCategoryFormalidate.value.validate().then(success => {
          if (success) {
                 isLoading.value = true
            store
              .dispatch('categories/Updatesponsors', { id, result })
              .then(response => {
                     isLoading.value = false
                Vue.swal({
                  icon: 'success',
                  title: 'Updated!',

                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    
    }
    return {
      refInputEl,
      categoriesList,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCategoryForm,
      inputImageRenderer,
      addCategoryFormalidate,
      id,
      CategoryForm,
      removeSpace,
      validLink,
      checkLink,
      save,
      required,
      email,
      isLoading
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }

  </style>
